import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {

	const [home, setHome] = useState(false)
	const [homeMultipage, setHomeMultipage] = useState(false)
	const [homeOnepage, setHomeOnepage] = useState(false)
	const [about, setAbout] = useState(false)
	const [services, setServices] = useState(false)
	const [blog, setBlog] = useState(false)
	const [pages, setPages] = useState(false)
	const [servicePages, setServicePages] = useState(false)
	const [caseStudies, setCaseStudies] = useState(false)
	const [shopPages, setShopPages] = useState(false)

	const openMobileMenu = menu => {

		if (menu === 'home') {
			setHome(!home)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'homeMultipage') {
			setHome(true)
			setHomeMultipage(!homeMultipage)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'homeOnepage') {
			setHome(true)
			setHomeMultipage(false)
			setHomeOnepage(!homeOnepage)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'about') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(!about)
			setServices(false)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'services') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(!services)
			setBlog(false)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'blog') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(!blog)
			setPages(false)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'pages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(!pages)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'servicePages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(!servicePages)
			setCaseStudies(false)
			setShopPages(false)
		}
		else if (menu === 'caseStudies') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(false)
			setCaseStudies(!caseStudies)
			setShopPages(false)
		}
		else if (menu === 'shopPages') {
			setHome(false)
			setHomeMultipage(false)
			setHomeOnepage(false)
			setAbout(false)
			setServices(false)
			setBlog(false)
			setPages(true)
			setServicePages(false)
			setCaseStudies(false)
			setShopPages(!shopPages)
		}
	};

	return (
		<div className="container relative">
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						<li>
							<Link to="/ana-sayfa" className={activeMenu === "/" ? "active-menu" : ""}>Ana Sayfa</Link>
						</li>
						<li>
							<Link to="/hakkimizda" className={activeMenu === "/hakkimizda" ? "active-menu" : ""}>Kurumsal</Link>
						</li>
						<li className={services ? "menu-item-has-children current-menu-item" : "menu-item-has-children"}>
							<Link to="#" onClick={() => { openMobileMenu('services'); }}>Hizmetlerimiz</Link>
							<ul className={services ? "sub-menu current-menu" : "sub-menu"}>
								<li>
									<Link to="/hizmetlerimiz" className={activeMenu === "/alt-giyim" ? "active-menu" : ""}>Alt Giyim</Link>
								</li>
								<li>
									<Link to="/hizmetlerimiz" className={activeMenu === "/ust-giyim" ? "active-menu" : ""}>Üst Giyim</Link>
								</li>
								<li>
									<Link to="/hizmetlerimiz" className={activeMenu === "/ozel-tasarim" ? "active-menu" : ""}>Özel Tasarım</Link>
								</li>
							</ul>
						</li>
						
						<li>
							<Link to="/moda-blog" className={activeMenu === "/moda-blog" ? "active-menu" : ""}>Moda-Blog</Link>			
						</li>
						<li>
							<Link to="/iletisim" className={activeMenu === "/iletisim" ? "active-menu" : ""}>İletişim</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default RSMobileMenu;
