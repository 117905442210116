import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listCounters } from '../../actions/CounterActions';
import SliderStyleThree from '../../components/Elements/Slider/SliderStyleThree';
import Service from './ServiceSection';
import About from './AboutSection';
import Process from './ProcessSection';
import Brand from '../../components/Common/Brand'
import ScrollToTop from '../../components/Common/ScrollTop';
import CounterStyleThree from '../../components/Elements/Counter/CounterStyleThree';
import brandBg1 from '../../assets/img/bg/partners-bg.jpg';

const HomeEightMain = () => {

	const dispatch = useDispatch()
	const counterList = useSelector(state => state.counterList)
	const { counters } = counterList

	useEffect(() => {
		dispatch(listCounters())
	}, [dispatch])
	return (
		<React.Fragment>

			<SliderStyleThree />
			<About />
			<Brand
				brandBg={brandBg1}
			/>
			<Service />
			<CounterStyleThree counters={counters} />
			<Process />
			<ScrollToTop />
		</React.Fragment>
	);
}

export default HomeEightMain;