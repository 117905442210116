import React from 'react';
import SingleServiceNumber from '../../components/Service/ServiceNumber';

import bgImg from '../../assets/img/bg/steps-area.png';

const bgStyle = {
    backgroundImage: `url(${bgImg})`
}

const Process = () => {

    return (
        <div id="rs-portfolio" className="rs-process style4 pt-120 pb-120 md-pt-80 md-pb-70" style={bgStyle}>
            <div className="container relative">
                <div className="sec-left">
                    <h2 className="title">Çalışma Süreci</h2>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-4 pr-30 md-pl-14 md-pb-100">
                        <div className="rs-step">
                            <div className="media-icon video-item">

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 pl-34 md-pl-14">
                        <div className="row">
                            <div className="col-md-6 mb-60">
                                <SingleServiceNumber
                                    itemClass="rs-addon-number"
                                    serviceNumber="1."
                                    Title="Modelleme & Kesim"
                                    Text="Güncel moda trendlerini, hızlı bir şekilde uyarlayıp müşterilerin beğenisine sunmaktayız."
                                />
                            </div>
                            <div className="col-md-6 mb-60">
                                <SingleServiceNumber
                                    itemClass="rs-addon-number"
                                    serviceNumber="2."
                                    Title="Dikim & Yıkama"
                                    Text="Hassasiyet ve hız gerektiren kesimde tam otomatik bilgisayarlı kesim makineleri kullanılmaktadır."
                                />
                            </div>
                            <div className="col-md-6 sm-mb-60">
                                <SingleServiceNumber
                                    itemClass="rs-addon-number"
                                    serviceNumber="3."
                                    Title="Ütü & Paket"
                                    Text="Hazırlanan ürünlerin kontrolleri yapıldıktan sonra ütülenip paketlenerek teslimata hazırdır."
                                />
                            </div>
                            <div className="col-md-6">
                                <SingleServiceNumber
                                    itemClass="rs-addon-number"
                                    serviceNumber="4."
                                    Title="Teslimat"
                                    Text="Siparişlerin zamanında teslim edilmesine özen gösteren firmamız için müşteri memnuniyetini çok önemlidir."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Process;