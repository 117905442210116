import React from 'react';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import SectionTitle from '../../components/Common/SectionTitle';
import ContactForm from '../../components/Contact/ContactForm';
import ContactInfo from '../../components/Contact/ContactInfo';
import ScrollToTop from '../../components/Common/ScrollTop';
import bannerbg from '../../assets/img/breadcrumbs/1.jpg';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const ContactMain = () => {

    let tab1 = "Merkez",
        tab2 = "Şube";

    return (
        <React.Fragment>

            {/* SiteBreadcrumb */}
            <SiteBreadcrumb
                pageTitle="İletişim"
                pageName="İletişim"
                breadcrumbsImg={bannerbg}
            />
            {/* SiteBreadcrumb */}

            {/* Contact Section Start */}
            <div className="rs-contact pt-120 md-pt-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 md-mb-60">
                            <ContactInfo />
                        </div>
                        <div className="col-lg-8 pl-70 md-pl-14">
                            <div className="contact-widget">
                                <SectionTitle
                                    sectionClass="sec-title2 mb-40"
                                    subtitleClass="sub-text contact mb-14"
                                    subtitle="BİZİMLE İLETİŞİME GEÇİN"
                                    titleClass="title testi-title"
                                    title="İletişim Formu"
                                />

                                {/* Contact Form */}
                                <ContactForm />
                                {/* Contact Form */}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="rs-single-shop" className="rs-single-shop pt-40 pb-80 md-pt-80 sm-pb-60">
                    <div className="container">
                        <div className="product-details-part">
                            <div className="tab-area">
                                <Tabs>
                                    <TabList className="nav nav-tabs" style={{ justifyContent: 'center' }}>
                                        <Tab><button>{tab1}</button></Tab>
                                        <Tab><button>{tab2}</button></Tab>
                                    </TabList>
                                    <TabPanel>
                                        <div className="tab-desc">
                                            <p className="dsc-p">
                                                <div className="map-canvas pt-40 md-pt-80">
                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3187.0165154204724!2d35.32054731515009!3d36.985538979910814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15288f5fb244e085%3A0xd4dd094d4ea38221!2sKocavezir%2C%2032033.%20Sk.%20No%3A15%2C%2001060%20Seyhan%2FAdana!5e0!3m2!1str!2str!4v1645572479265!5m2!1str!2str"></iframe>
                                                </div>
                                            </p>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="tab-desc">
                                            <p className="dsc-p">
                                                <div className="map-canvas pt-40 md-pt-80">
                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.655719799196!2d28.88161851527716!3d41.01090807930053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cabb17f7c0323f%3A0x88475e0d463c9a7f!2zTWVobWV0IE5lc2loIMOWem1lbiwgTGFkaW4gU2suIE5vOjE5LCAzNDE3MyBHw7xuZ8O2cmVuL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1645572268785!5m2!1str!2str"></iframe>

                                                </div>
                                            </p>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}
            </div>
            {/* Contact Section End */}
        </React.Fragment>

    );
}


export default ContactMain;