import SingleService from '../../components/Service/SingleService';

// Service Image Icons
import serviceIcon1 from '../../assets/img/service/style6/1.png';
import serviceIcon2 from '../../assets/img/service/style6/2.png';
import serviceIcon3 from '../../assets/img/service/style6/3.png';

import bgImg from '../../assets/img/bg/services-bg.jpg';

const bgStyle = {
    backgroundImage: `url(${bgImg})`
}

const Service = () => {

    return (
        <div id="rs-service" className="rs-services main-home style3 pt-120 pb-120 md-pt-80 md-pb-80" style={bgStyle}>
            <div className="container relative">
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-24">
                        <SingleService
                            itemClass="services-item"
                            serviceImage={serviceIcon1}
                            serviceURL="alt-giyim"
                            Title="Alt Giyim"
                            Text="Mont, ceket, kot ceket, kot gömlek, tulum, saloped gibi ürünler üretilmektedir."

                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-24">
                        <SingleService
                            itemClass="services-item"
                            serviceImage={serviceIcon2}
                            serviceURL="ust-giyim"
                            Title="Üst Giyim"
                            Text="Kot, gabardin, keten, kadife, tensel gibi çeşitli model ve kalıplarda ürünler üretilmektedir."

                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-24">
                        <SingleService
                            itemClass="services-item"
                            serviceImage={serviceIcon3}
                            serviceURL="ozel-tasarim"
                            Title="Özel Tasarım"
                            Text="İstenilen her türlü çalışmanın ar-ge çalışması yapılıp ürün temini sağlanır."

                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;