import axios from 'axios'
import {
    BLOG_LIST_REQUEST,
    BLOG_LIST_SUCCESS,
    BLOG_LIST_FAIL,

    BLOG_DETAILS_REQUEST,
    BLOG_DETAILS_SUCCESS,
    BLOG_DETAILS_FAIL,

    FULL_BLOG_LIST_REQUEST,
    FULL_BLOG_LIST_SUCCESS,
    FULL_BLOG_LIST_FAIL,

} from '../constants/BlogConstants'

export const listBlogs = (keyword = '') => async (dispatch) => {
    try {
        dispatch({ type: BLOG_LIST_REQUEST })
        const { data } = await axios.get(`https://ceketciler-website.herokuapp.com/api/blog${keyword}`)
        dispatch({
            type: BLOG_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: BLOG_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const fullListBlogs = () => async (dispatch) => {
    try {
        dispatch({ type: FULL_BLOG_LIST_REQUEST })
        const { data } = await axios.get(`https://ceketciler-website.herokuapp.com/api/fullblog`)
        dispatch({
            type: FULL_BLOG_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: FULL_BLOG_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
        })
    }
}


export const listBlogDetails = (slug) => async (dispatch) => {
    try {
        dispatch({ type: BLOG_DETAILS_REQUEST })
        const { data } = await axios.get(`https://ceketciler-website.herokuapp.com//api/blog/${slug}/`)
        dispatch({
            type: BLOG_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: BLOG_DETAILS_FAIL,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,

        })
    }
}
