import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
// Recent Post Thumb Images
import thumb1 from '../../assets/img/blog/inner/1.jpg'
import { fullListBlogs } from '../../actions/BlogActions';

const RecentPostWidget = () => {

    const dispatch = useDispatch()
    const blogFullList = useSelector(state => state.blogFullList)
    const { blogs } = blogFullList

    useEffect(() => {
        dispatch(fullListBlogs())
    }, [dispatch])

    return (
        <div className="recent-posts mb-50">
            <div className="widget-title">
                <h3 className="title">Son Haberler</h3>
            </div>
            {blogs.slice(0, 5).map((blog) => (
                <div key={blog.id} className="recent-post-widget">
                    <div className="post-img">
                        <Link to={`/moda-blog/${blog.slug}`}>
                            <img
                                style={{ width: '-webkit-fill-available', height: 60 }}
                                src={blog.blogImage}
                                alt=""
                            />
                        </Link>
                    </div>
                    <div className="post-desc">
                        <Link to={`/moda-blog/${blog.slug}`}>{blog.blogTitle}</Link>
                        <span className="date">
                            <i className="fa fa-calendar"></i>
                            {blog.blogPublishedDate}
                        </span>
                    </div>
                </div>
            ))}

        </div>
    )
}

export default RecentPostWidget;