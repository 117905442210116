import React from 'react';
import { Link } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu, activeMenu } = props;

    return (
        <React.Fragment>
            <li className={parentMenu === 'home' ? 'current-menu-item' : ''}>
                <Link to="" as="/" >Ana Sayfa</Link>
            </li>
            <li className={parentMenu === 'about' ? 'current-menu-item' : ''}>
                <Link to="/hakkimizda" className={activeMenu === "/hakkimizda" ? "active-menu" : ""}>Kurumsal</Link>
            </li>
            <li className={parentMenu === 'hizmetlerimiz' ? 'current-menu-item' : ''}>
                <Link to="/hizmetlerimiz" className={activeMenu === "/hizmetlerimiz" ? "active-menu" : ""} >Hizmetlerimiz</Link>
            </li>
            <li className={parentMenu === 'blog' ? 'current-menu-item' : ''}>
                <Link to='/moda-blog' className={activeMenu === "/moda-blog" ? "active-menu" : ""}>Moda/Blog</Link>
            </li>
            <li className={parentMenu === 'contact' ? 'current-menu-item' : ''}>
                <Link to="/iletisim" className={activeMenu === "/iletisim" ? "active-menu" : ""}>İletişim</Link>
            </li>
        </React.Fragment>
    );
}

export default MenuItems;