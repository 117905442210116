import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Process/SingleProcess';
import VideoStyleThree from '../../components/Video/VideoStyleThree';
// Working Process Images
import processImage1 from '../../assets/img/process/1.png';
import processImage2 from '../../assets/img/process/2.png';
import processImage3 from '../../assets/img/process/3.png';
import processImage4 from '../../assets/img/process/4.png';
import aboutImg from '../../assets/img/about/about-3.jpg';
import danceEffectImg from '../../assets/img/about/effect-1.png';
import effectImg1 from '../../assets/img/about/dotted-3.png';
import effectImg2 from '../../assets/img/about/shape3.png';
import MapChart from '../../components/WorldMap/MapChart';

const AboutMain = () => {

    return (
        <React.Fragment>
            {/* about-area-start */}
            <div id="rs-about" className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
                <div className="container">
                    <div className="row align-items-center" >
                        <div className="col-lg-6 md-mb-30">
                            <div className="rs-animation-shape">
                                <div className="images">
                                    <img src={aboutImg} alt="" />
                                </div>
                                <div className="middle-image2">
                                    <img className="dance" src={danceEffectImg} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-60 md-pl-14">
                            <div className="contact-wrap">
                                {/* Section Title Start */}
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text style-bg"
                                    subtitle="Hakkımızda"
                                    titleClass="title pb-36"
                                    title="CEKETÇİLER TEKSTİL"
                                    descClass="desc pb-34"
                                    description="Ceketçiler Tekstil 1955'den beri kendi sahip olduğu markalarda dahil olmak üzere tekstil hazır
                                    giyim ürünlerinin üretimini ve satışını bilgi ve tecrübesiyle başarılı bir şekilde yürütmektedir. Kurulduğu günden itibaren
                                    tekstil söktöründe üretim aşamalarına hakim olan uzman bir kadroya sahip olan Ceketçiler Tekstil birçok üretim kategorisinde
                                    üretim yapmaktadır. Kaliteli malzeme kullanımı,iş disiplini ve yetenekli bir ekip çalışmasıyla müşterileriyle kalıcı ilişkiler kurmayı hedefleyen
                                    Ceketçiler Tekstil ilk günkü gibi işini severek yapmaktadır. Hizmetlerimiz arasında üretim sonrası paketleme, yurt içi ve yurt dışı lojistik operasyonları
                                    da vardır. Avrupa'da birçok ülkeyle ve yurt içinde de farklı lokasyonlara sahip birçok firmayla da iş ilişkileri sürmektedir."
                                    secondDescClass="margin-0 pb-16"
                                    secondDescription="Biz Kimiz?
                                    Ceketçiler Tekstil 1955 yılında 2 kuşak önce dedemiz Mehmet Yıldız tarafından Adana’da kuruldu.
                                    İlk etapta Hakan Konfeksiyon olarak hizmet veren firmamız erkek takım elbise ve ceket üretimi ve satışıyla tekstil sektöründe ilerlemeye
                                    başladı. Bu süreçte yakalanılan kalite ve iyi iş ilişkileri sayesinde tekstil dünyasında Ceketçiler olarak anılmaya başlandı.
                                    Daha sonraki zamanlarda yani 1 kuşak öncesinde artan tecrübesi ve kapasitesiyle hizmet vermeye devam etti.
                                    Böylelikle yaklaşık 500 çalışma arkadaşıyla kurulan tekstil atölyesiyle birlikte yurt içinde ve yurt dışında birçok lokasyonda bugünde sürmekte olan
                                    kalıcı iş ilişkileri geliştirildi. Şimdiki zamandaysa tekstil dünyasındaki değişen talep ve dengeler de gözönünde bulundurularak profosyonel
                                    denim üretimine geçiş yapıldı. Dünya piyasasında ve markalaşma yolunda bizde varız diyebilmek için 2017'den itibaren sürdürülen çalışmalarla birlikte
                                    kalifiye bir yönetim ve verimli bir araştırma - geliştirme ekibiyle yola çıkıldı. Profosyonel denim üreticisi olarak bizler hem modayı yakından takip
                                    ediyor hem de en yeni ürünleri müşterilerimizle paylaşıyoruz. Şirketimiz bünyesinde tasarım ofisi, satış, planlama, kesim, dikim, kuru işlem, yıkama, ve paketleme
                                    bölümleri bulunmaktadır. Ceketçiler Tekstil olarak, birinci sınıf denim ve dokuma kumaşlardan erkek - kadın giyim olarak müşterilerimize hizmet vermekteyiz.
                                    İş felsefemiz, ilk günkü gibi uzun süren ilişkiler kurmak ve müşterilerimizin yüksek kalitede, zamanında teslimat beklentilerini karşılamaktır.
                                    En üst düzey müşteri memnuniyeti hedefimizdir ve daha iyi hizmet verebilmek için de her zaman daha yüksek bir çalışma temposuyla çalışmaktayız."
                                />
                                {/* Section Title End */}
                                <div className="btn-part">
                                    <Link className="readon learn-more" to="/iletisim">Daha Fazlasını Öğrenmek İçin</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-image">
                        <img className="top dance" src={effectImg1} alt="" />
                        <img className="bottom dance" src={effectImg2} alt="" />
                    </div>
                </div>
            </div>
            <div>

            </div>
            <div className="pt-80" style={{
                backgroundColor: '#08405c'
            }}>
                <MapChart />
            </div>
            <div className="rs-process style2 pt-120 pb-112 md-pt-80 md-pb-72">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-46"
                        subtitleClass="sub-text style-bg"
                        subtitle="SÜREÇ"
                        titleClass="title title2"
                        title="Çalışma Aşamaları"
                    />
                    <div className="row">
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage1}
                                titleClass="title"
                                Title="Model-Kesim"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 md-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage2}
                                titleClass="title"
                                Title="Dikim-Yıkama"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6 sm-mb-50">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage3}
                                titleClass="title"
                                Title="Ütü-Paket"
                            />
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <SingleProcess
                                itemClass="addon-process"
                                processImage={processImage4}
                                titleClass="title"
                                Title="Teslimat"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* working-process-area-end */}

            {/* VideoStyleThree area start */}
            <div class="rs-contact-wrap bg7 pt-120 pb-390 md-pt-80">
                <div class="container">
                    <SectionTitle
                        sectionClass="sec-title2 text-center mb-30"
                        subtitleClass="sub-text style-bg white-color"
                        subtitle="İLETİŞİM"
                        titleClass="title white-color"
                        title="Size Daha İyi Yardımcı Olabilmek İçin Bizimle İletişim Kurun"
                    />
                </div>
            </div>
            <VideoStyleThree />
            {/* VideoStyleThree area end */}

        </React.Fragment>
    )
}

export default AboutMain;
