import React from 'react';
import ContactForm from '../../components/Contact/ContactForm';


const VideoStyleThree = () => {

    return (
        <div className="rs-video-wrap style2 inner pb-120 md-pb-80">
            <div className="container">
                <div className="content-wrap">
                    <div className="row y-middle no-gutter">
                        <div className="col-lg-6">
                            <div className="video-item">
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="rs-requset">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default VideoStyleThree;