import {
    COUNTER_LIST_REQUEST,
    COUNTER_LIST_SUCCESS,
    COUNTER_LIST_FAIL,
} from '../constants/CounterConstants'

export const counterListReducer = (state = { counters: [] }, action) => {

    switch (action.type) {
        case COUNTER_LIST_REQUEST:

            return { counter_loading: true, counters: [] }

        case COUNTER_LIST_SUCCESS:

            return { counter_loading: false, counters: action.payload }

        case COUNTER_LIST_FAIL:

            return { counter_loading: false, counter_error: action.payload }

        default:

            return state
    }
}