import axios from 'axios';
import React, { useState } from 'react';

const ContactForm = (props) => {
    const { submitBtnClass } = props;

    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")


    const postMessage = async (e) => {
        await axios.post('https://ceketciler-website.herokuapp.com/api/message', { name, surname, phone, email, message })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <form id="contact-form" onSubmit={() => {
            postMessage()
        }}>
            <div className="row">
                <div className="col-md-6 mb-30">
                    <input className="from-control" onChange={(e) => setName(e.target.value)} type="text" id="name" name="name" placeholder="Ad" required />
                </div>
                <div className="col-md-6 mb-30">
                    <input className="from-control" onChange={(e) => setSurname(e.target.value)} type="text" id="surname" name="surname" placeholder="Soyad" required />
                </div>
                <div className="col-md-6 mb-30">
                    <input className="from-control" onChange={(e) => setEmail(e.target.value)} type="text" id="email" name="email" placeholder="E-Mail" required />
                </div>
                <div className="col-md-6 mb-30">
                    <input className="from-control" onChange={(e) => setPhone(e.target.value)} type="text" id="phone" name="phone" placeholder="Telefon" required />
                </div>
                <div className="col-12 mb-30">
                    <textarea className="from-control" onChange={(e) => setMessage(e.target.value)} id="message" name="message" placeholder="Açıklama" required></textarea>
                </div>
            </div>
            <div className="btn-part">
                <button className={submitBtnClass ? submitBtnClass : 'readon learn-more submit'} type="submit" >Gönder</button>
            </div>
        </form>
    );

}

export default ContactForm;
