import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { combineReducers } from 'redux'
import { blogListReducer, blogDetailsReducer, blogFullListReducer } from './reducers/BlogReducer'
import { counterListReducer } from './reducers/CounterReducer'
import { ExportCountryListReducer } from './reducers/ExportCountryReducer'

const reducer = combineReducers({
    blogList: blogListReducer,
    blogDetails: blogDetailsReducer,
    blogFullList: blogFullListReducer,
    counterList: counterListReducer,
    exportCountryList: ExportCountryListReducer

})

const initialState = {}
const middleware = [thunk]


const store = createStore(reducer, initialState, compose(applyMiddleware(...middleware), typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
    ? a => a
    : window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()))

export default store