
import { Link } from 'react-router-dom';

const SinglePost = (props) => {
    const { blogImage, blogTitle, blogDesc, blogAuthor, blogPublishedDate, slug } = props;
    return (
        <div className="blog-item">
            <div className="image-wrap">
                <Link to={`/moda-blog/${slug}`}>
                    <img style={{ width: '-webkit-fill-available', height: 500 }} src={blogImage} alt={blogTitle} />
                </Link>
            </div>
            <div className="blog-content">
                <ul className="blog-meta">
                    <li className="date"><i className="fa fa-calendar-check-o"></i> {blogPublishedDate}</li>
                    <li className="admin"><i className="fa fa-user-o"></i> {blogAuthor}</li>
                </ul>
                <h3 className="blog-title">
                    <Link to={`/moda-blog/${slug}`}>
                        {blogTitle}
                    </Link>
                </h3>
                <p className="desc">{blogDesc.substring(0, 450)}...</p>
                <div className="blog-button">
                    <Link to={`/moda-blog/${slug}`}>
                        Daha Fazla Öğren
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default SinglePost