import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Header from '../../components/Layout/Header';
import { listBlogs } from '../../actions/BlogActions';
import BlogMain from '../../components/Blog';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import Footer from '../../components/Layout/Footer';
import ScrollToTop from '../../components/Common/ScrollTop';
import SearchModal from '../../components/Layout/Header/SearchModal';
// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/1.jpg';
import Paginate from '../../components/Paginate/Paginate';

const Blog = ({ history }) => {

    const dispatch = useDispatch()
    const blogList = useSelector(state => state.blogList)
    const { blogs, page, pages } = blogList

    let keyword = history.location.search

    console.log(keyword)

    useEffect(() => {
        dispatch(listBlogs(keyword))
    }, [dispatch, keyword])

    return (
        <React.Fragment>
            <Header
                parentMenu='blog'
                activeMenu='/moda-blog'
            />
            <div>
                <SiteBreadcrumb
                    pageTitle="Moda-Blog"
                    titleClass="page-title"
                    pageName="Moda-Blog"
                    breadcrumbsImg={bannerbg}
                />

                {/* Blog Main */}
                <BlogMain blogs={blogs} />

                <div>
                    <Paginate pages={pages} page={page} keyword={keyword} />
                </div>

                {/* Blog Main End */}


                {/* scrolltop-start */}
                <ScrollToTop />
                {/* scrolltop-end */}

            </div>

            <Footer />
            <SearchModal />
        </React.Fragment>
    );
}


export default Blog;

