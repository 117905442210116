import React from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ServiceOneMain from '../../components/ServiceOne/ServiceOneMain';
import ScrollToTop from '../../components/Common/ScrollTop';
// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/1.jpg';


const Services = () => {

    return (
        <React.Fragment>
            <Header
                parentMenu='hizmetlerimiz'
                secondParentMenu='hizmetlerimiz'
                activeMenu='/hizmetlerimiz'
            />
            <div>
                <SiteBreadcrumb
                    pageTitle="Hizmetlerimiz"
                    titleClass="page-title"
                    pageName="Hizmetlerimiz"
                    breadcrumbsImg={bannerbg}
                />

                <ServiceOneMain />
                <ScrollToTop />
            </div>
            <Footer />
            <SearchModal />
        </React.Fragment>

    );
}


export default Services;