import React from 'react';
import PostContent from './PostContent';
import SearchWidget from '../Widget/SearchWidget';
import RecentPostWidget from '../Widget/RecentPostWidget';

const BlogDetailsMain = ({ blog }) => {

    return (
        <div className="rs-inner-blog pt-120 pb-120 md-pt-90 md-pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 order-last">
                        <div className="widget-area">
                            <SearchWidget />
                            <RecentPostWidget />
                        </div>
                    </div>
                    <div className="col-lg-8 pr-34 md-pr-14">
                        <div className="blog-details">
                            <PostContent blog={blog} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogDetailsMain;