import React from 'react';
import ContactForm from '../../components/Contact/ContactForm';
import SectionTitle from '../../components/Common/SectionTitle';


const VideoStyleTwo = () => {

    return (
        <div className="rs-video-wrap style3 pt-120 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="content-wrap">
                    <div className="row margin-0 grdiant-bg y-middle">
                        <div className="col-lg-6 padding-0">
                            <div className="video-item">

                            </div>
                        </div>
                        <div className="col-lg-6 padding-0">
                            <div className="rs-requset">
                                <SectionTitle
                                    sectionClass="sec-title2 mb-34"
                                    subtitleClass="sub-text white-color"
                                    subtitle="Mesaj Formu"
                                    titleClass="title testi-title white-color"
                                    title="Sizinle İletişim Kurmamız İçin Bize Mesaj Gönderin"
                                />
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default VideoStyleTwo;