export const BLOG_LIST_REQUEST = 'BLOG_LIST_REQUEST'
export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS'
export const BLOG_LIST_FAIL = 'BLOG_LIST_FAIL'

export const BLOG_DETAILS_REQUEST = 'BLOG_DETAILS_REQUEST'
export const BLOG_DETAILS_SUCCESS = 'BLOG_DETAILS_SUCCESS'
export const BLOG_DETAILS_FAIL = 'BLOG_DETAILS_FAIL'

export const FULL_BLOG_LIST_REQUEST = 'FULL_BLOG_LIST_REQUEST'
export const FULL_BLOG_LIST_SUCCESS = 'FULL_BLOG_LIST_SUCCESS'
export const FULL_BLOG_LIST_FAIL = 'FULL_BLOG_LIST_FAIL'