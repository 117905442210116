import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
//Custom Components
import HomeEight from '../pages/home-8';
import About from '../pages/about';
import Services from '../pages/service';
import Contact from '../pages/contact';
import LoadTop from '../components/Common/ScrollTop/LoadTop'
import Blog from '../pages/blog';
import BlogDetails from '../pages/blog/blog-details';


const App = () => {
    return (
        <div className='App'>
            <Router>
                <LoadTop />
                <Switch>
                    <Route path="/" exact component={HomeEight} />
                    <Route path="/ana-sayfa" component={HomeEight} />
                    <Route path="/hakkimizda" component={About} />
                    <Route path="/hizmetlerimiz" exact component={Services} />
                    <Route exact path="/moda-blog" component={Blog} />
                    <Route exact path="/moda-blog/:id" component={BlogDetails} />
                    <Route path="/iletisim" component={Contact} />
                </Switch>
            </Router>

        </div>
    );
}

export default App;
