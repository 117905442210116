import {
    BLOG_LIST_REQUEST,
    BLOG_LIST_SUCCESS,
    BLOG_LIST_FAIL,

    BLOG_DETAILS_REQUEST,
    BLOG_DETAILS_SUCCESS,
    BLOG_DETAILS_FAIL,
    FULL_BLOG_LIST_REQUEST,
    FULL_BLOG_LIST_SUCCESS,
    FULL_BLOG_LIST_FAIL,
} from '../constants/BlogConstants'

export const blogListReducer = (state = { blogs: [] }, action) => {

    switch (action.type) {
        case BLOG_LIST_REQUEST:

            return { blog_loading: true, blogs: [] }

        case BLOG_LIST_SUCCESS:

            return { blog_loading: false, blogs: action.payload.results, page: action.payload.page, pages: action.payload.pages }

        case BLOG_LIST_FAIL:

            return { blog_loading: false, blog_error: action.payload }

        default:

            return state
    }
}

export const blogFullListReducer = (state = { blogs: [] }, action) => {
    switch (action.type) {
        case FULL_BLOG_LIST_REQUEST:

            return { blog_loading: true, blogs: [] }

        case FULL_BLOG_LIST_SUCCESS:

            return { blog_loading: false, blogs: action.payload }

        case FULL_BLOG_LIST_FAIL:

            return { blog_loading: false, blog_error: action.payload }

        default:

            return state
    }
}
export const blogDetailsReducer = (state = { blog: {} }, action) => {
    switch (action.type) {

        case BLOG_DETAILS_REQUEST:

            return { blog_loading: true, ...state }

        case BLOG_DETAILS_SUCCESS:

            return { blog_loading: false, blog: action.payload }

        case BLOG_DETAILS_FAIL:

            return { blog_loading: false, blog_error: action.payload }

        default:

            return state
    }
}