import axios from 'axios'
import {
    COUNTER_LIST_REQUEST,
    COUNTER_LIST_SUCCESS,
    COUNTER_LIST_FAIL,
} from '../constants/CounterConstants'

export const listCounters = () => async (dispatch) => {
    try {
        dispatch({ type: COUNTER_LIST_REQUEST })
        const { data } = await axios.get(`https://ceketciler-website.herokuapp.com/api/artir`)
        dispatch({
            type: COUNTER_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: COUNTER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
