import React from "react";
import { WorldMap } from "react-svg-worldmap"
import SectionTitle from "../Common/SectionTitle";

const data = [
    { country: 'fr', value: 67390000 }, // france
    { country: 'es', value: 47350000 }, // spain
    { country: 'be', value: 11560000 }, // belgium
    { country: 'by', value: 9399000 }, // belarus
    { country: 'dk', value: 58310000 }, // denmark
    { country: 'de', value: 83240000 }, // germany
    { country: 'ng', value: 208679114 }, // nigeria
    { country: 'no', value: 5379000 }, // norway
    { country: 'nl', value: 17440000 }, // holland
];


const MapChart = () => {

    return (
        <div>
            <SectionTitle
                sectionClass="sec-title2 text-center mb-30"
                subtitleClass="sub-text style-bg white-color"
                subtitle="YURT DIŞI"
                titleClass="title white-color"
                title="İhracat Yaptığımız Ülkeler"
            />
            <div className="col-lg-12" style={{ alignItems: 'center', justifyContent: 'center', flex: 1, display: 'flex' }}>
                <WorldMap color="#042433" value-suffix="people" size="xl" data={data} />
            </div>
        </div>
    );
};

export default MapChart;