import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';

import footerLogo1 from '../../../assets/img/logo/logo.png';

const Footer = (props) => {
    const { footerLogo, footerClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4">
                            <div className="footer-logo">
                                <Link to="/" ><img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" /></Link>
                            </div>
                            <div className="textwidget pb-30">
                                <p>1955'den Beri Aynı Heyecan ve Artan Tecrübemizle Siz Değerli Müşterilerimize Hizmet Vermekteyiz.</p>
                            </div>
                            <ul className="footer-social md-mb-30">
                                <li>
                                    <Link to="#"><i className="fa fa-facebook"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-twitter"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-pinterest"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-instagram"></i></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
                            <h3 className="widget-title">Hizmetlerimiz</h3>
                            <ul className="site-map">
                                <li><Link to="/hizmetlerimiz">Alt Giyim</Link></li>
                                <li><Link to="/hizmetlerimiz">Üst Giyim</Link></li>
                                <li><Link to="/hizmetlerimiz">Özel Tasarım</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-lg-6 col-sm-12 md-mb-30">
                            <h3 className="widget-title">İletişim</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">Merkez: Kocavezir Mah. 32033 Sok. No:15/A Seyhan/Adana</div>
                                    <div className="desc">Şube: Mehmet Nesih Özmen Mah. Ladin Sok. No:19/1 Güngören/Merter/İstanbul</div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">Merkez:
                                        <a href="tel:(0322) 359 36 55"> (0322) 359 36 55</a>
                                    </div>
                                    <div className="desc">Şube:
                                        <a href="tel:(0212) 577 37 57"> (0212) 577 37 57</a>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                        <a href="mailto:info@ceketciler.com.tr">info@ceketciler.com.tr</a>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                    <div className="desc">
                                        Çalışma Saatleri: 09.00 - 17.30
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;
