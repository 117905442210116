import {
    EXPORT_COUNTRY_LIST_REQUEST,
    EXPORT_COUNTRY_LIST_SUCCESS,
    EXPORT_COUNTRY_LIST_FAIL,
} from '../constants/ExportCountryConstants'

export const ExportCountryListReducer = (state = { export_countrys: [] }, action) => {

    switch (action.type) {
        case EXPORT_COUNTRY_LIST_REQUEST:

            return { export_country_loading: true, export_countrys: [] }

        case EXPORT_COUNTRY_LIST_SUCCESS:

            return { export_country_loading: false, export_countrys: action.payload }

        case EXPORT_COUNTRY_LIST_FAIL:

            return { export_country_loading: false, export_country_error: action.payload }

        default:

            return state
    }
}