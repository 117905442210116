import React from 'react';
import SectionTitle from '../Common/SectionTitle';

const ContactInfo = (props) => {
    const { contactBoxClass } = props;
    return (
        <div className={contactBoxClass ? contactBoxClass : 'contact-box'}>
            <SectionTitle
                sectionClass="sec-title mb-44"
                subtitleClass="sub-text new-text white-color"
                subtitle="CEKETÇİLER TEKSTİL"
                titleClass="title white-color"
                title="İLETİŞİM YOLLARI"
            />
            <div className="address-box mb-24">
                <div className="address-icon">
                    <i className="fa fa-phone"></i>
                </div>
                <div className="address-text">
                    <span className="label">Tel(şube):</span>
                    <a href="tel:(0212) 577 37 57">(0212) 577 37 57</a>
                    <span className="label">Tel(merkez):</span>
                    <a href="tel:(0322) 359 36 55">(0322) 359 36 55</a>
                </div>
            </div>
            <div className="address-box mb-24">
                <div className="address-icon">
                    <i className="fa fa-home"></i>
                </div>
                <div className="address-text">
                    <span className="label">Email:</span>
                    <a href="mailto:info@ceketciler.com.tr">info@ceketciler.com.tr</a>
                </div>
            </div>
            <div className="address-box">
                <div className="address-icon">
                    <i className="fa fa-map-marker"></i>
                </div>
                <div className="address-text">
                    <span className="label">Adres (şube):</span>
                    <div className="desc">Mehmet Nesih Özmen Mahallesi Ladin Sokak No:19/1 Güngören/Merter/İstanbul</div>
                    <span className="label">Adres (merkez):</span>
                    <div className='desc'>Kocavezir Mahallesi 32033 Sokak No:15/A Seyhan/Adana</div>

                </div>
            </div>
        </div>
    );

}

export default ContactInfo;