import React from 'react';
import SearchWidget from '../Widget/SearchWidget';
import RecentPostWidget from '../Widget/RecentPostWidget';
import SinglePost from './SinglePost';

const BlogMain = ({ blogs }) => {

    return (
        <div className="rs-blog rs-inner-blog pt-120 pb-60 md-pt-90 md-pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 order-last">
                        <div className="widget-area">
                            <SearchWidget />
                            <RecentPostWidget />
                        </div>
                    </div>

                    <div className="col-lg-8 pr-34 md-pr-14">
                        {blogs.map((blog) => (
                            <div key={blog.id}>
                                <SinglePost
                                    blogImage={blog.blogImage}
                                    blogPublishedDate={blog.blogPublishedDate}
                                    blogTitle={blog.blogTitle}
                                    blogDesc={blog.blogDescription}
                                    blogButtonClass='blog-button inner-blog'
                                    blogAuthor={blog.blogAuthor}
                                    slug={blog.slug}

                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogMain;