import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const SearchWidget = () => {
    const [keyword, setKeyword] = useState('')

    let history = useHistory()

    const submitHandler = e => {
        e.preventDefault()
        if (keyword) {
            history.push(`/moda-blog/?keyword=${keyword}&page=1`)
        } else {
            history.push('/moda-blog')
        }
        console.log(keyword)
    }

    return (
        <form
            onSubmit={submitHandler}
        >
            <div className="search-widget mb-50">
                <div className="search-wrap">
                    <input type="search" placeholder="Arama Yap..." name="s" className="search-input" value={keyword} onChange={event => setKeyword(event.target.value)} />
                    <button type="submit" value="Search"><i className="flaticon-search"></i></button>
                </div>
            </div>
        </form>

    )
}

export default SearchWidget;