import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleProcess from '../../components/Common/Process';
import VideoStyleTwo from '../../components/Video/VideoStyleTwo';
import aboutImg1 from '../../assets/img/about/1.jpg';
import aboutImg2 from '../../assets/img/about/2.jpg';
import aboutImg3 from '../../assets/img/about/3.jpg';

const ServiceOneMain = () => {
    return (
        <React.Fragment>
            <div className="rs-about pt-40 pb-120 md-pt-80 md-pb-80" style={{ borderBottom: "3px solid rgb(8,64,92)" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle=""
                                titleClass="title"
                                title="ALT GİYİM"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="Alt giyim kategorisinde kadın ve erkek farketmeksizin dilediğiniz kumaştan güncel modaya uygun olarak ürünler üretilebilir.
                                Etek,pantolon,şort,tulum gibi ürünler bu kategoride gösterilebilir. Yani denim üretiminin yanında kapsamlı bir şekilde diğer ürün kategorilerinde de üretim yapmaktayız.
                                Kullandığımız malzeme ve işçilik en yüksek kalite de tutularak siz değerli müşterilerimize hizmet vermekteyiz"
                                secondDescClass="desc"
                                secondDescription=""
                            />
                        </div>
                        <div className="col-lg-6" style={{ alignItems: 'center', justifyContent: 'center', flex: 1, display: 'flex' }}>
                            <div className="images">
                                <img style={{ width: 360, height: 480 }} src={aboutImg1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rs-about pt-40 pb-120 md-pt-80 md-pb-80" style={{ borderBottom: "3px solid rgb(8,64,92)" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle=""
                                titleClass="title"
                                title="ÜST GİYİM"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="Üst giyim kategorisinde güncel modaya uygun olarak
                                mont, ceket, kot ceket, kot gömlek, tulum, salopet elbise gibi ürünler üretilmektedir. Bu kategoride de kadın ve
                                erkek farketmeksizin dilediğiniz kumaştan birinci sınıf malzeme kullanarak kaliteli ürünler üretilebilir."
                                secondDescClass="desc"
                                secondDescription=""
                            />
                        </div>
                        <div className="col-lg-6" style={{ alignItems: 'center', justifyContent: 'center', flex: 1, display: 'flex' }}>
                            <div className="images">
                                <img src={aboutImg2} alt="" style={{ width: 360, height: 480 }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rs-about pt-40 pb-120 md-pt-80 md-pb-80" style={{ borderBottom: "3px solid rgb(8,64,92)" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title4"
                                subtitleClass="sub-text"
                                subtitle=""
                                titleClass="title"
                                title="ÖZEL TASARIM"
                                effectClass="heading-line"
                            />
                            <SectionTitle
                                sectionClass="sec-title4"
                                descClass="desc desc-big"
                                description="Alanında uzman tasarım ekibimizle birlikte istenilen her türlü ürünün ar-ge çalışması yapılarak
                                müşterilerimizin hayallerindeki ürünleri gerçeğe dönüştürebiliriz. Süreç içerisinde hem sahip olduğumuz ekip kalitesi bakımından
                                hem de kullandığımız malzeme kalitesi bakımından ortaya çıkan ürünün sizin hayallerinizdeki üründen daha da fazla fark yaratması için
                                tecrübemizle ve iş disiplinimizle çalışmaktayız. "
                                secondDescClass="desc"
                                secondDescription=""
                            />
                        </div>
                        <div className="col-lg-6">
                            <div className="images" style={{ alignItems: 'center', justifyContent: 'center', flex: 1, display: 'flex' }}>
                                <img src={aboutImg3} alt="" style={{ width: 360, height: 480 }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rs-process style5 bg5 pt-120 pb-120 md-pt-80 md-pb-64" >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 md-mb-40">
                            <div className="process-wrap">
                                <SectionTitle
                                    sectionClass="sec-title mb-30"
                                    subtitleClass="sub-text new"
                                    subtitle="ÇALIŞMA SÜRECİ"
                                    titleClass="title white-color"
                                    title="Müşterilerimizin Memnuniyeti İçin Nasıl Çalışıyoruz"
                                />
                                <div className="btn-part mt-40">
                                    <Link to="/iletisim">
                                        <a className="readon learn-more contact-us">Bizimle İletişime Geçin</a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 pl-30 md-pl-15">
                            <div className="row">
                                <div className="col-md-6 mb-40">
                                    <SingleProcess
                                        processNumber="1"
                                        processTitle="Model & Kesim"
                                        processText="Güncel moda trendlerini, hızlı bir şekilde uyarlayıp müşterilerin beğenisine sunmaktayız."
                                    />
                                </div>
                                <div className="col-md-6 mb-40">
                                    <SingleProcess
                                        processNumber="2"
                                        processTitle="Dikim & Yıkama"
                                        processText="Hassasiyet ve hız gerektiren kesimde tam otomatik bilgisayarlı kesim makineleri kullanılmaktadır."
                                    />
                                </div>
                                <div className="col-md-6 sm-mb-40">
                                    <SingleProcess
                                        processNumber="3"
                                        processTitle="Ütü & Paket"
                                        processText="Hazırlanan ürünlerin kontrolleri yapıldıktan sonra ütülenip paketlenerek teslimata hazırdır."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <SingleProcess
                                        processNumber="4"
                                        processTitle="Teslimat"
                                        processText="Siparişlerin zamanında teslim edilmesine özen gösteren firmamız için müşteri memnuniyetini çok önemlidir."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <VideoStyleTwo />

        </React.Fragment>
    );
}

export default ServiceOneMain;