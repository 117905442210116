import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Paginate = ({ pages, page, keyword = '' }) => {

    const pageNumber = window.location.href.split('page=')
    let pageN = pageNumber[1]

    if (keyword) {
        keyword = keyword.split('?keyword=')[1].split('&')[0]
    }

    return (
        <div className="col-lg-8 pb-60" style={{ alignItems: 'center', justifyContent: 'center', flex: 0.3, display: 'flex' }}>
            {pages > 1 && (
                <Pagination >
                    <Pagination.First href={`/moda-blog/?keyword=${keyword}&page=1`} />
                    <Pagination.Prev href={pageN > 1 ? `/moda-blog/?keyword=${keyword}&page=${pageN - 1}` : `/moda-blog/?keyword=${keyword}&page=1`} />

                    {[...Array(pages).keys()].map((x) =>
                        <LinkContainer
                            key={x + 1}
                            to={`/moda-blog/?keyword=${keyword}&page=${x + 1}`}
                        >
                            <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
                        </LinkContainer>
                    )}

                    <Pagination.Next href={pageN === undefined ? `/moda-blog/?keyword=${keyword}&page=2` : pageN < pages ? `/moda-blog/?keyword=${keyword}&page=${++pageN}` : pageN === pages && `/hukuk-blog/?keyword=${keyword}&page=${pages}`} />
                    <Pagination.Last href={`/moda-blog/?keyword=${keyword}&page=${pages}`} />
                </Pagination>

            )
            }
        </div>
    )
}

export default Paginate