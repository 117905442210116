import React from 'react';
import { Link } from 'react-router-dom';

const FooterBottom = () => {
    return (
        <div className="footer-bottom">
            <div className="container">
                <div className="row y-middle">
                    <div className="col-lg-6 text-right md-text-center md-mb-10 order-last">
                        <ul className="copy-right-menu">
                            <li><Link to="/">Ana Sayfa</Link></li>
                            <li><Link to="/hakkimizda">Kurumsal</Link></li>
                            <li><Link to="/hizmetlerimiz">Hizmetlerimiz</Link></li>
                            <li><Link to="/moda-blog">Moda-Blog</Link></li>
                            <li><Link to="/iletisim">İletişim</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <div className="copyright md-text-center md-mb-10">
                            <p>Gizlilik Politikası | © 2022 Ceketçiler Tekstil. Tüm Hakları Saklıdır.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterBottom;