import React from 'react';

const PostContent = ({ blog }) => {

    return (
        <div className="post-content">
            <div className="bs-img mb-34">
                <img
                    src={blog.blogImage}
                    alt=""
                />
            </div>
            <div className="blog-full">
                <ul className="single-post-meta">
                    <li>
                        <span className="p-date">
                            <i className="fa fa-calendar"></i>
                            {blog.blogPublishedDate}
                        </span>
                    </li>
                    <li>
                        <span className="p-date">
                            <i className="fa fa-user"></i>
                            {blog.blogAuthor}
                        </span>
                    </li>
                </ul>
                <h3 className="blog-title">
                    {blog.blogTitle}
                </h3>
                <p>{blog.blogDescription}</p>
            </div>
        </div>
    )
}

export default PostContent;