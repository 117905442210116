import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
// About Image Icons
import aboutImg from '../../assets/img/about/about-7.jpg';

// Service Image Icons
import leftShapeImg from '../../assets/img/service/shape-2.png';
import rightShapeImg from '../../assets/img/service/shape-3.png';

const About = () => {

    return (
        <div id="rs-about" className="rs-about relative style3 pt-120 pb-120 md-pt-74 md-pb-80">
            <div className="container relative">
                <div className="row align-items-center">
                    <div className="col-lg-6 pr-56 md-pl-14 md-mb-30">
                        <SectionTitle
                            sectionClass="sec-title mb-30 zIndex"
                            subtitleClass="sub-text"
                            subtitle="Hakkımızda"
                            titleClass="title title3 pb-30"
                            title="Ceketçiler Tekstil"
                            descClass="desc pb-30"
                            description="Ceketçiler Tekstil 1955'den beri kendi sahip olduğu markalarda dahil olmak üzere tekstil hazır
                            giyim ürünlerinin üretimini ve satışını bilgi ve tecrübesiyle başarılı bir şekilde yürütmektedir. Kurulduğu günden itibaren
                            tekstil söktöründe üretim aşamalarına hakim olan uzman bir kadroya sahip olan Ceketçiler Tekstil birçok üretim kategorisinde
                            üretim yapmaktadır. Kaliteli malzeme kullanımı,iş disiplini ve yetenekli bir ekip çalışmasıyla müşterileriyle kalıcı ilişkiler kurmayı hedefleyen
                            Ceketçiler Tekstil ilk günkü gibi işini severek yapmaktadır. Hizmetlerimiz arasında üretim sonrası paketleme, yurt içi ve yurt dışı lojistik operasyonları
                            da vardır. Avrupa'da birçok ülkeyle ve yurt içinde de farklı lokasyonlara sahip birçok firmayla da iş ilişkileri sürmektedir."
                            secondDescription="Biz Kimiz?
                            Ceketçiler Tekstil, dünya piyasasında ve markalaşma yolunda bizde varız demek için, 1955 yılında Adana’da  kuruldu.
                            Ceketçiler Tekstil, kalifiye bir yönetim ekibine ve verimli bir araştırma – geliştirme ekibine sahiptir.
                            Profesyonel denim üreticisi olarak modayı yakından takip ediyor ve en yeni ürünleri müşterilerimizle paylaşıyoruz.
                            Şirketimiz; tasarım ofisi, satış, planlama, kesim, dikim, kuru işlem, yıkama ve paketleme bölümlerinden oluşmaktadır.
                            Ceketçiler Tekstil  olarak, birinci sınıf denim ve dokuma kumaşlardan erkek giyim olarak müşterilerimize hizmet vermekteyiz.
                            İş felsefemiz, uzun süren ilişkiler kurmak ve müşterinin yüksek kalitede, zamanında teslimat beklentilerini karşılamak üzerine
                            kurulmuştur. En üst düzey müşteri memnuniyeti hedefimizdir ve müşterilerimize daha iyi hizmet verebilmek için her zaman daha
                            fazla çalışmaktayız."
                        />
                        <div className="btn-part mt-40">
                            <Link className="readon learn-more learn-btn" to="/hakkimizda">Daha Fazlası</Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="home8-about zIndex">
                            <img
                                src={aboutImg}
                                alt="images"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-part">
                <div className="left-side">
                    <img
                        src={leftShapeImg}
                        alt=""
                    />
                </div>
                <div className="right-side">
                    <img
                        src={rightShapeImg}
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
}

export default About;