import React from 'react';

const SingleProcess = (props) => {
    return (
        <div className={props.itemClass} style={{ alignItems: 'center', justifyContent: 'center', flex: 1, display: 'flex', flexDirection: 'column' }} >
            <div className="process-img">
                <img
                    style={{ width: 180, height: 180 }}
                    src={props.processImage}
                    alt={props.Title}
                />
            </div>
            <div className="process-text">
                <h3 className={props.titleClass}>{props.Title}</h3>
            </div>
        </div>
    )
}

export default SingleProcess