import React from 'react';
import Header from '../../components/Layout/Header';
import BlogDetailsMain from '../../components/BlogDetails';
import Footer from '../../components/Layout/Footer';
import SearchModal from '../../components/Layout/Header/SearchModal';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import ScrollToTop from '../../components/Common/ScrollTop';
import { listBlogDetails } from '../../actions/BlogActions';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
// Breadcrumbs Background Image
import bannerbg from '../../assets/img/breadcrumbs/1.jpg';

const BlogDetails = ({ match }) => {

    const dispatch = useDispatch()

    const blogDetails = useSelector(state => state.blogDetails)

    const { blog } = blogDetails

    useEffect(() => {
        dispatch(listBlogDetails(match.params.id))
    }, [dispatch, match])
    return (
        <React.Fragment>

            <Header
                parentMenu='blog'
                activeMenu='/blog-details'
            />

            <SiteBreadcrumb
                pageTitle={blog.blogTitle}
                titleClass="page-title new-title pb-10"
                pageCategory="Moda-Blog"
                pageName={blog.blogTitle}
                breadcrumbsImg={bannerbg}
            />

            {/* Blog Main */}
            <BlogDetailsMain blog={blog} />
            {/* Blog Main End */}

            {/* scrolltop-start */}
            <ScrollToTop />
            {/* scrolltop-end */}

            <Footer />

            <SearchModal />
        </React.Fragment>
    );
}


export default BlogDetails;

